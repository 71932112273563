<template>
  <div class="world-class-analytics">
    <div class="world-class-analytics-info">
      <div class="top-part">
        <h1>World-class analytics included free with</h1>
        <img src="img/HP-Main-Logo.svg" alt="" />
      </div>
      <div
        v-motion
        class="bot-part"
        :initial="{
          opacity: 0,
          y: 100,
        }"
        :visibleOnce="{
          opacity: 1,
          y: 0,
          transition: {
            delay: 100,
            stiffness: 200,
            damping: 90,
            mass: 3,
          },
        }"
      >
        <img src="img/aws.svg" alt="" />
        <img src="img/google-analytics.svg" alt="" />
        <img src="img/data-studio.svg" alt="" />
        <img src="img/send-grid.svg" alt="" />
        <img src="img/fb-pixel.png" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.world-class-analytics {
  width: 100%;
}

.world-class-analytics-info {
  width: 71rem;
  margin: auto;
  border-left: 2px solid white;
  padding-top: 1rem;
  padding-bottom: 5rem;
}

.top-part {
  display: flex;
  width: 80%;
  margin: auto;
}

.top-part h1 {
  font-weight: 700;
}

.top-part img {
  width: 20%;
  margin-left: 0.8rem;
}

.bot-part {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0;
  margin-top: 2rem;
}

.bot-part img {
  width: 65%;
  margin: auto;
}

.bot-part img:nth-child(1) {
  width: 50%;
}

.bot-part img:nth-child(2),
.bot-part img:nth-child(3),
.bot-part img:nth-child(4) {
  width: 80%;
}

@media only screen and (max-width: 1400px) {
  .world-class-analytics-info {
    width: 60rem;
  }

  .top-part {
    width: 40rem;
    margin: auto;
  }

  .top-part h1 {
    font-size: 1.5rem;
  }

  .top-part img {
    width: 18%;
  }
}

@media only screen and (max-width: 1050px) {
  .world-class-analytics-info {
    width: 40rem;
  }

  .top-part {
    width: 26.8rem;
    margin: auto;
  }

  .top-part h1 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 650px) {
  .world-class-analytics-info {
    width: 90%;
    border-left: none;
  }

  .top-part {
    display: grid;
    align-items: center;
    width: 90%;
    margin: auto;
  }

  .top-part h1 {
    font-size: 0.9rem;
    text-align: center;
  }

  .top-part img {
    width: 30%;
    align-items: center;
    display: block;
    margin: auto;
  }

  .bot-part {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
}
</style>
